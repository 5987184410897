import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import session from "../../services/session";
import request from "../../services/request";
import { STANDARDS, STANDARDS_LOG } from "../../Routes/Api";
import moment from "moment";
import { useDispatch } from "react-redux";
import { getLogStandardsListSuccess } from "../../store/actions/additionalStandards";
import utils from "../../utils";

const customStyles = {
    rows: {
        style: {
            borderBottom: "none !important",
            color: "",
        },
    },
    headCells: {
        style: {
            background: "#F6F1F1",
            height: "25px",
            borderBottom: "none !important",
            fontSize: "14px",
            fontWeight: "600",
            color: "#686868",
        },
    },
    cells: {
        style: {},
    },
};

const Overview = () => {
    const dispatch = useDispatch();

    const [filteredList, setFilteredList] = useState([]);
    const [standardLogs, setStandardLogs] = useState([]);
    const [toggle, setToggle] = useState(false);
    const [toggleIndex, setToggleIndex] = useState(-1);
    const [logStatus, setLogStatus] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);

    const org = session.getOrgId();
    const user = session.getUser();

    const toggleCard = (currentIndex) => {
        setToggleIndex(currentIndex);
        if (currentIndex === toggleIndex) {
            setToggle(!toggle);
        } else {
            setToggle(true);
        }
    };

    const columns = [
        {
            name: "Standards",
            selector: (row) => row?.label,
            width: "250px",
            cell: (row) => row?.label,
        },
        {
            name: "Amendment Log",
            cell: (row, ind) => (
                <>
                    <div>
                        <p className="mb-1">
                            {row?.logs[0]?.date &&
                                moment(row?.logs[0]?.date).format("Do MMM YYYY")}
                        </p>
                        <p className="mb-1">{row?.logs[0]?.ammendment}</p>
                        <p className="mb-1">{row?.logs[0]?.description}</p>
                    </div>

                    <div>
                        {toggle &&
                            ind === toggleIndex &&
                            row?.logs?.slice(1).map((log, index) => {
                                return (
                                    <>
                                        <div key={index} className="mt-5">
                                            <p className="mb-1">
                                                {row?.logs[0]?.date &&
                                                    moment(log?.date).format("Do MMM YYYY")}
                                            </p>
                                            <p className="mb-1">{log?.ammendment}</p>
                                            <p className="mb-1">{log?.description}</p>
                                        </div>
                                    </>
                                );
                            })}
                    </div>

                    {row?.logs.length > 1 && (
                        <a
                            onClick={() => toggleCard(ind)}
                            className="float-right color-05 cursor text-decoration-none"
                        >
                            <i className="fal fa-angle-double-right pr-2 fs-16"></i>
                            {toggle === true && ind === toggleIndex
                                ? "Close all amendments"
                                : "View all amendments"}
                        </a>
                    )}
                </>
            ),
        },

        {
            name: "Status",
            width: "150px",
            cell: (row, ind) => (
                <>
                    {!user?.permissions?.general?.system_manager && ind > 0 ? (
                        <div
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Only the system manager has the permission to toggle the status On or Off."
                        >
                            <label className="log_status_switch log_status">
                                <input
                                    type="checkbox"
                                    disabled={
                                        !utils.checkSubStatus()
                                            ? !utils.checkSubStatus()
                                            : ind === 0 && currentPage === 1
                                    }
                                    checked={row?.enable}
                                    onChange={(e) => updateStatus(e?.target?.checked, row, ind)}
                                    className={row?.default && "custom-disabled"}
                                />
                                <span className="slider_status round"></span>
                                <i className="fas fa-times"></i>
                                <i className="fas fa-check"></i>
                            </label>
                            <span className="color-06 pl-2">
                                {row?.enable ? "On" : "Off"}
                            </span>
                        </div>
                    ) : (
                        <div>
                            <label className="log_status_switch log_status">
                                <input
                                    type="checkbox"
                                    disabled={
                                        !utils.checkSubStatus()
                                            ? !utils.checkSubStatus()
                                            : ind === 0 && currentPage === 1
                                    }
                                    checked={row?.enable}
                                    onChange={(e) => updateStatus(e?.target?.checked, row, ind)}
                                    className={row?.default && "custom-disabled"}
                                />
                                <span className="slider_status round"></span>
                                <i className="fas fa-times"></i>
                                <i className="fas fa-check"></i>
                            </label>
                            <span className="color-06 pl-2">
                                {row?.enable ? "On" : "Off"}
                            </span>
                        </div>
                    )}
                </>
            ),
        },
    ];

    const subHeaderComponentMemo = React.useMemo(() => {
        return (
            <div className="align-items-center d-flex">
                <p className="mb-0 mr-3 fs-16 color-05 fw-500">Downloads:</p>
                <a
                    href="https://techni-k.co.uk/audit-app/procedure/"
                    target="_blank"
                    className="mr-3 overview-btn-download"
                >
                    <i className="far fa-file-download" /> Procedure
                </a>
                <a
                    href="https://techni-k.co.uk/auditapp/setup-guide"
                    target="_blank"
                    className="mr-3 overview-btn-download"
                >
                    <i className="far fa-file-download" /> Setup guide
                </a>
                <a
                    href="https://techni-k.co.uk/audit-app/user-guide/"
                    target="_blank"
                    className="overview-btn-download"
                >
                    <i className="far fa-file-download" /> User guide
                </a>
            </div>
        );
    }, []);

    const updateStatus = (value, row, index) => {
        if (!utils.checkSubStatus()) {
            return;
        } else {
            if (user?.permissions?.general?.system_manager) {
                let obj = {
                    orgId: org,
                    value: row?.value,
                    mode: value ? "enable" : "disable",
                };
                request.put(STANDARDS.ADD_REMOVE_STANDARDS, obj, true, false).then(
                    (res) => {
                        if (res) {
                            let tempArr = filteredList;
                            tempArr[index].enable = value;
                            setLogStatus(!logStatus);
                        }
                    },
                    (error) => { }
                );
            }
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        setFilteredList([...standardLogs]);
    }, [standardLogs]);

    useEffect(() => {
        request.get(`${STANDARDS_LOG.GET_STANDARDS_LOGS}/${org}`, true).then(
            (res) => {
                if (res?.status) {
                    setStandardLogs(res?.data);
                    dispatch(getLogStandardsListSuccess(res?.data));
                }
            },
            (error) => { }
        );
    }, [logStatus]);

    return (
        <div className="gmp_inspections_wrapper standards_logs">
            <DataTable
                columns={columns}
                data={filteredList}
                customStyles={customStyles}
                striped
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                persistTableHead
                pagination
                fixedHeaderScrollHeight="calc(100vh - 400px)"
                fixedHeader={true}
                onChangePage={handlePageChange}
            />
        </div>
    );
};

export default Overview;
