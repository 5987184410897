import React from "react";
import StripeWidget from "./StripeWidget";
import MailImg from "../../Assets/images/mail.png";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import request from "../../services/request";
import { STRIPE_VALIDATION } from "../../Routes/Api";

const Thanks = () => {
    const { state } = useLocation();
    const location = useLocation();
    const queryParams = new URLSearchParams(location?.search);
    const session = queryParams.get("session_id");

    useEffect(() => {
        let obj = {
            id: session,
        };
        if (session) {
            request.post(STRIPE_VALIDATION.VALIDATION_SESSION, obj, true, false).then(
                (res) => { },
                (error) => { }
            );
        }
    }, [session]);

    return (
        <>
            <section className="thanks">
                <div className="container-fluid">
                    {!session && (
                        <h1 className="fs-24 fw-400 color-02">You're all good to go!</h1>
                    )}

                    <div className={session ? "row justify-content-center" : "row"}>
                        {!session && (
                            <div className="col-12 col-md-6 col-lg-6">
                                <p className="fs-18 fw-600 color-06">Plan details</p>
                                <StripeWidget />
                            </div>
                        )}

                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="mail-box">
                                <div className="mail-icon">
                                    <img src={MailImg} className="img-fluid" alt="Mail" />
                                </div>
                                <h2>We've sent you an email {state?.first_name}!</h2>
                                <p>
                                    Head over to your email and check out the one we've sent you
                                    about logging into your account for the first time.
                                </p>
                                <p>
                                    You will shortly receive your welcome email that contains everything you need to get started.
                                </p>
                                <p>
                                    If you want to dive straight in, you can read through{" "}
                                    <a
                                        href={"https://techni-k.co.uk/audit-app/user-guide/"}
                                        target="_blank"
                                    >
                                        your super-helpful user guide
                                    </a>{" "}
                                    to ensure you get the most out of audIT.app.
                                </p>
                                <p>
                                    And finally, don't forget we're here to help, so if you need
                                    us use the live chat, drop us an{" "}
                                    <a href="mailto:support@techni-k.co.uk">email</a> or give us a
                                    call. We just love talking to our awesome customers!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Thanks;
