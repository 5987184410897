import React, { useState } from "react";
import request from "../../../services/request";
import { UPLOAD_SHEET } from "../../../Routes/Api";
import { APP_CONSTANTS } from "../../../constants";

const UploadSpreadsheet = () => {
    const [auditFile, setAuditFile] = useState(null);
    const [ccFile, setCcFile] = useState(null);
    const [amendmentsFile, setAmendmentsFile] = useState(null);
    const [gmpFile, setGmpFile] = useState(null);
    const [auditLoader, setAuditLoader] = useState(false);
    const [ccLoader, setCcLoader] = useState(false);
    const [amendmentsLoader, setAmendmentsLoader] = useState(false);
    const [gmpLoader, setGmpLoader] = useState(false);
    const [auditSuccess, setAuditSuccess] = useState({
        show: false,
        message: null,
    });
    const [ccSuccess, setCcSuccess] = useState({
        show: false,
        message: null,
    });
    const [amendmentsSuccess, setAmendmentsSuccess] = useState({
        show: false,
        message: null,
    });
    const [gmpSuccess, setGmpSuccess] = useState({
        show: false,
        message: null,
    });
    const [auditError, setAuditError] = useState({
        show: false,
        message: null,
    });
    const [ccError, setCcError] = useState({
        show: false,
        message: null,
    });
    const [amendmentsError, setAmendmentsError] = useState({
        show: false,
        message: null,
    });
    const [gmpError, setGmpError] = useState({
        show: false,
        message: null,
    });

    const handleFileAudit = (value) => {
        const ext = value?.name?.split(/[#?]/)[0].split(".").pop().trim();
        if (ext !== "xlsx") {
            setAuditError({
                show: true,
                message: "Only accept file with extension .xlsx.",
            });
        } else {
            setAuditFile(value);
            setAuditError({
                show: false,
                message: null,
            });
        }
    };

    const handleCc = (value) => {
        const ext = value?.name?.split(/[#?]/)[0].split(".").pop().trim();
        if (ext !== "xlsx") {
            setCcError({
                show: true,
                message: "Only accept file with extension .xlsx.",
            });
        } else {
            setCcFile(value);
            setCcError({
                show: false,
                message: null,
            });
        }
    };

    const handleAmendments = (value) => {
        const ext = value?.name?.split(/[#?]/)[0].split(".").pop().trim();
        if (ext !== "xlsx") {
            setAmendmentsError({
                show: true,
                message: "Only accept file with extension .xlsx.",
            });
        } else {
            setAmendmentsFile(value);
            setAmendmentsError({
                show: false,
                message: null,
            });
        }
    };

    const handleGmpFile = (value) => {
        const ext = value?.name?.split(/[#?]/)[0].split(".").pop().trim();
        if (ext !== "xlsx") {
            setGmpError({
                show: true,
                message: "Only accept file with extension .xlsx.",
            });
        } else {
            setGmpFile(value);
            setGmpError({
                show: false,
                message: null,
            });
        }
    };

    const uploadAuditFile = () => {
        if (auditFile === null) {
            setAuditError({
                show: true,
                message: "Please select a file to upload.",
            });
        } else {
            setAuditLoader(true);
            const formData = new FormData();
            formData.append("files", auditFile);
            request.post(UPLOAD_SHEET.UPLOAD_SPREADSHEET, formData, true, true).then(
                (res) => {
                    setAuditSuccess({
                        show: true,
                        message: "File uploaded successfully!",
                    });
                    setAuditLoader(false);
                    setTimeout(() => {
                        setAuditSuccess({ show: false, message: null });
                    //}, APP_CONSTANTS.MODAL_SHOW_TIME);
						 }, 30000);
                },
                (err) => {
                    setAuditError({
                        show: true,
                        message: "Something went wrong, please try again.",
                    });
                    setAuditLoader(false);
                    setTimeout(() => {
                        setAuditError({ show: false, message: null });
                    //}, APP_CONSTANTS.MODAL_SHOW_TIME);
						 }, 30000);
                }
            );
        }
    };

    const uploadCcFile = () => {
        if (ccFile === null) {
            setCcError({
                show: true,
                message: "Please select a file to upload.",
            });
        } else {
            setCcLoader(true);
            const formData = new FormData();
            formData.append("files", ccFile);
            request
                .post(UPLOAD_SHEET.UPLOAD_SPREADSHEET_CC, formData, true, true)
                .then(
                    (res) => {
                        setCcSuccess({
                            show: true,
                            message: "File uploaded successfully!",
                        });
                        setCcLoader(false);
                        setTimeout(() => {
                            setCcSuccess({ show: false, message: null });
                       // }, APP_CONSTANTS.MODAL_SHOW_TIME);
							 }, 30000);
                    },
                    (err) => {
                        setCcError({
                            show: true,
                            message: "Something went wrong, please try again.",
                        });
                        setCcLoader(false);
                        setTimeout(() => {
                            setCcError({ show: false, message: null });
                       // }, APP_CONSTANTS.MODAL_SHOW_TIME);
							 }, 30000);
                    }
                );
        }
    };

    const uploadAmendmentsFile = () => {
        if (amendmentsFile === null) {
            setAmendmentsError({
                show: true,
                message: "Please select a file to upload.",
            });
        } else {
            setAmendmentsLoader(true);
            const formData = new FormData();
            formData.append("files", amendmentsFile);
            request.post(UPLOAD_SHEET.UPLOAD_AMENDMENTS, formData, true, true).then(
                (res) => {
                    setAmendmentsSuccess({
                        show: true,
                        message: "File uploaded successfully!",
                    });
                    setAmendmentsLoader(false);
                    setTimeout(() => {
                        setAmendmentsSuccess({ show: false, message: null });
                   // }, APP_CONSTANTS.MODAL_SHOW_TIME);
						 }, 30000);
                },
                (err) => {
                    setAmendmentsError({
                        show: true,
                        message: "Something went wrong, please try again.",
                    });
                    setAmendmentsLoader(false);
                    setTimeout(() => {
                        setAmendmentsError({ show: false, message: null });
                    //}, APP_CONSTANTS.MODAL_SHOW_TIME);
						 }, 30000);
                }
            );
        }
    };

    const uploadGmpFile = () => {
        if (gmpFile === null) {
            setGmpError({
                show: true,
                message: "Please select a file to upload.",
            });
        } else {
            setGmpLoader(true);
            const formData = new FormData();
            formData.append("files", gmpFile);
            request
                .post(UPLOAD_SHEET.UPLOAD_GMP_SPREADSHEET, formData, true, true, false)
                .then(
                    (res) => {
                        setGmpSuccess({
                            show: true,
                            message: "File uploaded successfully!",
                        });
                        setGmpLoader(false);
                        setTimeout(() => {
                            setGmpSuccess({ show: false, message: null });
                       // }, APP_CONSTANTS.MODAL_SHOW_TIME);
							 }, 30000);
                    },
                    (err) => {
                        setGmpError({
                            show: true,
                            message: "Something went wrong, please try again.",
                        });
                        setGmpLoader(false);
                        setTimeout(() => {
                            setGmpError({ show: false, message: null });
                        }, 30000);
                    }
                );
        }
    };

    return (
        <section className="upload-sheet">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-4">
                        <h2 className="color-03 fs-24 fw-600 mb-4">
                            Let's upload spreadsheet
                        </h2>
                        <div>
                            <h3 className="color-05 fs-18 fw-600 mb-2">
                                Upload system audit questions
                            </h3>
                            <div className="">
                                <input
                                    type="file"
                                    className="custom-file-input"
                                    id="audit-question"
                                    placeholder="Select File"
                                    aria-describedby="audit-question"
                                    accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                    onChange={(e) => handleFileAudit(e.target.files[0])}
                                    onClick={(event) => {
                                        event.target.value = null;
                                    }}
                                    disabled={auditLoader}
                                />
                                <label
                                    className="custom-file-button d-block mb-0 custom_text_overflow"
                                    htmlFor="audit-question"
                                >
                                    {auditFile?.name ? auditFile?.name : "Select File..."}
                                    {auditLoader ? (
                                        <a className="btn-browse fs-12" htmlFor="audit-question">
                                            <span
                                                className="spinner-border spinner-border-sm"
                                                role="status"
                                                aria-hidden="true"
                                            />{" "}
                                            Loading
                                        </a>
                                    ) : (
                                        <a className="btn-browse" htmlFor="audit-question">
                                            <i className="far fa-folder-open" /> Browse
                                        </a>
                                    )}
                                </label>
                                {auditSuccess?.show && (
                                    <small className="text-success">
                                        {auditSuccess?.message}
                                    </small>
                                )}
                                {auditError?.show && (
                                    <small className="text-danger">{auditError?.message}</small>
                                )}
                                <div className="text-right">
                                    <button
                                        className="btn-send"
                                        onClick={uploadAuditFile}
                                        disabled={auditLoader}
                                    >
                                        <i className="fal fa-upload"></i> Upload
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="mt-4">
                            <h3 className="color-05 fs-18 fw-600 mb-2">
                                Upload cc questions
                            </h3>
                            <div className="">
                                <input
                                    type="file"
                                    className="custom-file-input"
                                    id="cc-question"
                                    placeholder="Select File"
                                    aria-describedby="cc-question"
                                    accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                    onChange={(e) => handleCc(e.target.files[0])}
                                    onClick={(event) => {
                                        event.target.value = null;
                                    }}
                                    disabled={ccLoader}
                                />
                                <label
                                    className="custom-file-button d-block mb-0 custom_text_overflow"
                                    htmlFor="cc-question"
                                >
                                    {ccFile?.name ? ccFile?.name : "Select File..."}
                                    {ccLoader ? (
                                        <a className="btn-browse fs-12" htmlFor="cc-question">
                                            <span
                                                className="spinner-border spinner-border-sm"
                                                role="status"
                                                aria-hidden="true"
                                            />{" "}
                                            Loading
                                        </a>
                                    ) : (
                                        <a className="btn-browse" htmlFor="cc-question">
                                            <i className="far fa-folder-open" /> Browse
                                        </a>
                                    )}
                                </label>
                                {ccSuccess?.show && (
                                    <small className="text-success">{ccSuccess?.message}</small>
                                )}
                                {ccError?.show && (
                                    <small className="text-danger">{ccError?.message}</small>
                                )}
                                <div className="text-right">
                                    <button
                                        className="btn-send"
                                        onClick={uploadCcFile}
                                        disabled={ccLoader}
                                    >
                                        <i className="fal fa-upload"></i> Upload
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="mt-4">
                            <h3 className="color-05 fs-18 fw-600 mb-2">Upload amendments</h3>
                            <div className="">
                                <input
                                    type="file"
                                    className="custom-file-input"
                                    id="amendments"
                                    placeholder="Select File"
                                    aria-describedby="amendments"
                                    accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                    onChange={(e) => handleAmendments(e.target.files[0])}
                                    onClick={(event) => {
                                        event.target.value = null;
                                    }}
                                    disabled={amendmentsLoader}
                                />
                                <label
                                    className="custom-file-button d-block mb-0 custom_text_overflow"
                                    htmlFor="amendments"
                                >
                                    {amendmentsFile?.name
                                        ? amendmentsFile?.name
                                        : "Select File..."}
                                    {amendmentsLoader ? (
                                        <a className="btn-browse fs-12" htmlFor="amendments">
                                            <span
                                                className="spinner-border spinner-border-sm"
                                                role="status"
                                                aria-hidden="true"
                                            />{" "}
                                            Loading
                                        </a>
                                    ) : (
                                        <a className="btn-browse" htmlFor="amendments">
                                            <i className="far fa-folder-open" /> Browse
                                        </a>
                                    )}
                                </label>
                                {amendmentsSuccess?.show && (
                                    <small className="text-success">
                                        {amendmentsSuccess?.message}
                                    </small>
                                )}
                                {amendmentsError?.show && (
                                    <small className="text-danger">
                                        {amendmentsError?.message}
                                    </small>
                                )}
                                <div className="text-right">
                                    <button
                                        className="btn-send"
                                        onClick={uploadAmendmentsFile}
                                        disabled={amendmentsLoader}
                                    >
                                        <i className="fal fa-upload"></i> Upload
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="mt-4">
                            <h3 className="color-05 fs-18 fw-600 mb-2">
                                Upload gmp questions
                            </h3>
                            <div className="">
                                <input
                                    type="file"
                                    className="custom-file-input"
                                    id="gmp-question"
                                    placeholder="Select File"
                                    aria-describedby="gmp-question"
                                    accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                    onChange={(e) => handleGmpFile(e.target.files[0])}
                                    onClick={(event) => {
                                        event.target.value = null;
                                    }}
                                    disabled={gmpLoader}
                                />
                                <label
                                    className="custom-file-button d-block mb-0 custom_text_overflow"
                                    htmlFor="gmp-question"
                                >
                                    {gmpFile?.name ? gmpFile?.name : "Select File..."}
                                    {gmpLoader ? (
                                        <a className="btn-browse fs-12" htmlFor="gmp-question">
                                            <span
                                                className="spinner-border spinner-border-sm"
                                                role="status"
                                                aria-hidden="true"
                                            />{" "}
                                            Loading
                                        </a>
                                    ) : (
                                        <a className="btn-browse" htmlFor="gmp-question">
                                            <i className="far fa-folder-open" /> Browse
                                        </a>
                                    )}
                                </label>
                                {gmpSuccess?.show && (
                                    <small className="text-success">{gmpSuccess?.message}</small>
                                )}
                                {gmpError?.show && (
                                    <small className="text-danger">{gmpError?.message}</small>
                                )}
                                <div className="text-right">
                                    <button
                                        className="btn-send"
                                        onClick={uploadGmpFile}
                                        disabled={gmpLoader}
                                    >
                                        <i className="fal fa-upload"></i> Upload
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default UploadSpreadsheet;
